const api_url = process.env.NODE_ENV === "production" ? "https://dips400.com" : "http://localhost:8080"

export async function createUser(user) {
    try {
        const response = await fetch(`${api_url}/api/user/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(user)
            })

        switch (response.status) {
            case 201:
                return await response.json();
            default:
                const { error } = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function checkLogin(infos) {
    try {
        const response = await fetch(`${api_url}/api/user/login`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(infos)
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 400:
                return null;
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function updateUser(updatedUser) {
    try {
        const response = await fetch(`${api_url}/api/user/update`,
            {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(updatedUser)
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function deleteUser(userID) {
    try {
        const response = await fetch(`${api_url}/api/user/delete/${userID}`,
            {
                method: 'DELETE'
            })

    } catch (error) {
        console.error(error.message)
    }
}

export async function searchUsers(name) {
    try {
        const response = await fetch(`${api_url}/api/user/search?name=${name}`,
            {
                method: 'GET'
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 400:
                return [];
            default:
                const error = await response.json();
                throw error;
        }
    } catch (error) {
        console.error(error.message)
    }
}

export async function getLicenseKeysFromUserId(user) {
    try {
        const response = await fetch(`${api_url}/api/user/${user._id}/license-keys`,
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + user.token
                }
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return [];
            case 401:
                window.location = '/login'
                return [];
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function sendOtp(email) {
    try {
        const response = await fetch(`${api_url}/api/user/password-recovery/send-otp`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: email })
            })

        const message = await response.json();
        return { code: response.status, message: message }

    } catch (error) {
        console.error(error.message)
    }
}

export async function checkOtp(email, otp) {
    try {
        const response = await fetch(`${api_url}/api/user/password-recovery/check-otp`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: email, otp: otp })
            })

        const message = await response.json();
        return { code: response.status, message: message }

    } catch (error) {
        console.error(error.message)
    }
}

export async function checkEmailAvailability(email) {
    try {
        const response = await fetch(`${api_url}/api/user/check-email-availability`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: email })
            })
        const code = await response.json()
        return code

    } catch (error) {
        console.error(error.message)
    }
}