import updateHead from "../../utils/helper/updatePageHead";
import CheckoutForm from "./tmpl/CheckoutForm";
import { useState } from "react";
import { useUser } from "../../utils/contexts/userContext";
import { useLocation } from "react-router-dom";
import { PeasysFreeTrial, PeasysClassic } from "../../data/Pricing Plan/PricingCardInfo";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ConfigurationForm from "./tmpl/ConfigurationForm";

const stripePromise = loadStripe("pk_live_51ORsa6I27ExSp7sFwVHo3OUMXEdT3IfU3G1WljcvGCwxi2MY9TTDoluCZy9Uy9t7pirfLhPf8Outeg0IkWiRvtqp009nPY7SKH");

export default function Payement() {
    const title = "DIPS | Effectuez le payement"
    const cssPath = "/style/payement.css"

    const { user } = useUser();

    const [clientSecret, setClientSecret] = useState("");

    const end_date = new Date()
    end_date.setMonth(end_date.getMonth() + 2)
    const [infos, setInfos] = useState({
        user: user._id,
        key: "",
        start_date: new Date(),
        end_date: end_date,
        partition_name: "",
        is_available: false,
        max_user_count: 0,
        model_number: "",
        serie_number: "",
        os_version: "",
        subscription: {
            id: "",
            item_id: ""
        }
    })

    const search = useLocation().search;
    const pricingData = [PeasysFreeTrial, PeasysClassic]
    const index = new URLSearchParams(search).get('pricing-plan') - 2
    if(index < 0){
        window.history.back()
        return
    }
    const pricing_plan = pricingData[index];

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-centered-layout">
                            <h1 className="section-content-title">Votre Plan : {pricing_plan.title}</h1>
                            <div className="section-payement-plan-info-container">
                                <div style={{ margin: '5px 0' }}>
                                    <span style={{ fontSize: '45px' }}>{pricing_plan.price}€</span>
                                    <span style={{ fontSize: '12px' }}>/mois</span>
                                </div>
                                <p>{pricing_plan.subtitle}</p>
                                <ul className="section-payement-plan-list">
                                    {pricing_plan.descritpion_list.map((info, index) =>
                                    (<li key={info + index} className="section-payement-plan-infos-item">
                                        <img src="/assets/hexagon-check.svg" alt="company logo" style={{ width: '20px' }} />
                                        <h4>{info}</h4>
                                    </li>))}
                                </ul>
                            </div>

                            <ConfigurationForm
                                pricingPlan={pricing_plan}
                                infos={infos}
                                handleInfosChange={setInfos}
                                handleClientSecretChange={setClientSecret} />

                            {clientSecret && (
                                <>
                                    <h2>Paiement</h2>
                                    <div className="section-payement-payement-container">
                                        <Elements options={options} stripe={stripePromise}>
                                            <CheckoutForm infos={infos} />
                                        </Elements>
                                    </div>
                                </>)
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}