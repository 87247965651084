import updateHead from "../../utils/helper/updatePageHead";
import Footer from "../Footer/Footer"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import HomeServices from "./tmpl/HomeServices";
import Lottie from 'react-lottie';
import animationData from '../../lotties/data_home_1.json';
import animationData2 from '../../lotties/data_home_2.json';

export default function Home() {
    const title = "DIPS | Plongez dans le monde IBMi";
    const cssPath = "/style/home.css";

    const [counter, setCounter] = useState(1);

    const handleNext = () => {
        setCounter((counter + 1) % 3)
    };

    useEffect(() => {
        let interval = setInterval(() => handleNext(), 5000);
        return () => clearInterval(interval);
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1" id="section-home-top">
                            <Lottie
                                options={defaultOptions} />
                            <section className="section-content-container">
                                <header>
                                    <h1 className={"section-content-title " + ((counter === 0) ? "show" : "not-show")}>
                                        <span className="highlight-title">Développez</span> les intéractions de vos applicatifs IBMi...
                                    </h1>
                                    <h1 className={"section-content-title " + ((counter === 1) ? "show" : "not-show")}>
                                        <span className="highlight-title">Développez</span> des logiciels métiers basés sur votre IBMi...
                                    </h1>
                                    <h1 className={"section-content-title " + ((counter === 2) ? "show" : "not-show")}>
                                        <span className="highlight-title">Développez</span> des interfaces graphiques pour votre IBMi...
                                    </h1>
                                    <h2 className="section-content-subtitle">Et assurer la gestion optimale de vos données</h2>
                                </header>
                                <p className="section-content-description">
                                    DIPS porte le savoir-faire d'IBM et la puissance de l’<a href="https://www.ibm.com/fr-fr/power ">IBMi</a> aux services
                                    des <a href="https://www.tiobe.com/tiobe-index/">nouvelles technologies</a>. Avec sa solution Peasys, DIPS ouvre l'IBMi aux
                                    univers Windows, Linux, MacOs. DIPS, c'est aussi des solutions de développements logiciels spécifiques à vos métiers,
                                    un accompagnement dans vos projets et de la veille technologique.
                                </p>
                                <footer className="section-content-links-container">
                                    <Link to="/contact">Contactez l'équipe</Link>
                                    <Link to="/register">
                                        <button className="dark-bg">S'inscrire</button>
                                    </Link>
                                </footer>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section dark-bg">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1">
                            <div className="section-content-container">
                                <header>
                                    <h1 className="section-content-title">
                                        <span className="highlight-title">Augmentez la portabilité</span> de vos applicatifs au travers du service Peasys
                                    </h1>
                                    <h2 className="section-content-subtitle white-before">En donnant accès à la base de données IBMi</h2>
                                </header>

                                <div className="section-content-description">
                                    <p>
                                        <a href="https://github.com/dips400" style={{ color: 'white' }}>Peasys</a> est un outil d’interaction fiable et sécurisé pour le développement de vos applicatifs clients IBMi (logiciel,
                                        app mobile, app web). La librairie Peasys offre aux développeurs la possibilité d’intégrer la base de données IBMi au sein
                                        de leurs programmes.
                                    </p>
                                    <p>
                                        <a href="https://github.com/dips400" style={{ color: 'white' }}>Peasys</a> suit une architecture client-serveur et définit un ensemble de règles et d’outils (API) pour assurer une communication
                                        performante, sécurisée et efficace de bout en bout.
                                    </p>
                                </div>

                                <div className="section-content-links-container">
                                    <div style={{ display: "flex", height: 'min(50px,10vw)' }}>
                                        <a href="https://learn.microsoft.com/fr-fr/dotnet/csharp/">
                                            <img src="/assets/csharp.svg" alt="csharp" className="icon" style={{ height: '45px' }} />
                                        </a>
                                        <a href="https://www.python.org/">
                                            <img src="/assets/python.svg" alt="python" className="icon" style={{ height: '45px' }} />
                                        </a>
                                        <a href="https://www.php.net/manual/fr/intro-whatis.php">
                                            <img src="/assets/php-logo.svg" alt="java" className="icon" style={{ height: '45px' }} />
                                        </a>
                                        <a href="https://www.java.com/en/download/help/whatis_java.html">
                                            <img src="/assets/java.svg" alt="java" className="icon" style={{ height: '45px' }} />
                                        </a>
                                    </div>
                                    <Link to="/docs">
                                        <button className="light-bg">Documentation</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="section-home-iframe-415s74">
                                <div className="section-centered-layout section-columns-layout" grid-template="1,1" style={{gap:'10px'}}>
                                    <div className="section-row-layout" style={{ marginTop: '50px', gap: '10px' }}>
                                        <div className="section-info-container">
                                            <img src="/assets/dashboard.svg" className="icon" style={{ height: '45px' }} />
                                            <h4>Performance quasi-native</h4>
                                            <span>Découvrez notre benchmark</span>
                                        </div>
                                        <div className="section-info-container">
                                            <img src="/assets/time-quarter.svg" className="icon" style={{ height: '45px' }} />
                                            <h4>1 heure</h4>
                                            <span>pour intégrer la solution et commencer le développement.</span>
                                        </div>
                                    </div>
                                    <div className="section-row-layout" style={{ marginBottom: '50px', gap: '10px' }}>
                                        <div className="section-info-container">
                                            <img src="/assets/square-code.svg" className="icon" style={{ height: '45px' }} />
                                            <h4>4 langages</h4>
                                            <span>de programmation qui supportent actuellement Peasys.</span>
                                        </div>
                                        <div className="section-info-container">
                                            <img src="/assets/secure-shield.png" className="icon" style={{ height: '45px' }} />
                                            <h4>Sécurité</h4>
                                            <span>Respect des processus de sécurité du serveur IBMi.</span>
                                        </div>
                                    </div>
                                </div>
                                <Lottie
                                    options={defaultOptions2} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeServices />
            <Footer />
        </>
    )
}