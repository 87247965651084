export const commandCode = {
    csharp : {
        data: `using Peasys;

namespace CommandOs
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "FUTUR_LICENSE_KEY", false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaCommandResponse commandRes = conn.ExecuteCommand("ADDLIBLE SCHEMA_TABLE");
            Console.WriteLine("Commande correstement effectuée : " + commandRes.HasSucceeded);
            foreach (string warning in commandRes.warnings)
            {
                Console.WriteLine(warning);
            }

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "FUTUR_LICENSE_KEY")
print(conn.connexion_message)

res = conn.execute_command("ADDLIBLE SCHEMA_TABLE")
for data in res.warnings:
    print(data)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "FUTUR_LICENSE_KEY", true);
echo $conn->connexionMessage;

$commandResponse = $conn->executeCommand("ADDLIBLE SCHEMA_TABLE");
foreach($commandResponse->warnings as $warning) {
    echo "". $warning ."";
}

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;

public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "FUTUR_LICENSE_KEY", true);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
            
            PeaCommandResponse commandRes = conn.executeCommand("ADDLIBLE DIPS_NC");

            System.out.println("Commande correctement effectuée : " + commandRes.hasSucceeded);
            for(String str : commandRes.warnings){
                System.out.println(str);
            }

            conn.disconnect();
        } catch (Exception e) {
            System.out.println(e.getMessage());
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeCommand("COMMAND")
print(res.hasSucceeded)

conn.disconnect()
` },
    ruby: {
        data: `require 'peasys_swift'

conn = PeaClient.new("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", false, false)

insertresponse = conn.execute_command("COMMAND")
puts "Requete correctement exécutée : " + insertResponse.hasSucceeded

conn.disconnect` }
}