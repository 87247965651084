import updateHead from "../../../utils/helper/updatePageHead";
import CodeBlockWrapper from "../../Utils/CodeBlockWrapper";
import { installCommands } from "../../../data/Documentation/InstallData";
import { Link } from "react-router-dom";

export default function DocInstallation() {
    const title = "DIPS | Documentation Peasys";
    const cssPath = "/style/documentation.css";

    return (
        <>
            {updateHead({ title, cssPath })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Lancez-vous dans l'installation de Peasys</h1>
                                <h2 className="section-content-subtitle">Et commencez à développer aussi tôt</h2>
                            </header>
                            <p>
                                Le service Peasys étant une API entre vos serveurs IBMi et vos postes clients, il est donc nécéssaire d'installer Peasys aux deux
                                extrémités de votre système. Suivez pas à pas le processus afin de mieux comprendre son intégration dans votre SI.
                            </p>
                        </section>
        
                        <section className="section-content-container" id="peasys-administrator-install">
                            <h1 className="section-subtitle-special">Installation côté serveur</h1>
                            <p>
                                <bold>PeasysAdministrator</bold> est une application développée par DIPS qui permet toute l'administration de la partie serveur du service Peasys.
                                Elle permet notamment une installation paramétrée du service, la création de plusieurs environnements de travail et la mise à jour de Peasys.
                            </p>
                            <a href="http://localhost:8080/api/download/peasys-administrator">Installer Peasys Administrator</a>
                            <p>
                                Téléchargez en cliquant sur le lien ci-dessus l'archive contenant le logiciel et ses dépendances. Décompressez l'archive à
                                l'emplacement de votre choix et lancez l'application. Suivez les instructions pour l'installation.
                            </p>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Installation côté client</h1>
                            <p>
                                L'extrémité client prend la forme d'une librairie disponible dans une multitude de language de programmation actuel. Il vous suffit de
                                l'ajouter commme dépendance à vos logiciels pour pouvoir l'intégrer à vos développement. N'hésitez pas à
                                nous <Link to='/contact' style={{ display: 'unset' }}>soumettre vos besoins</Link> pour orienter la réplication de la libraire
                                dans vos outils de travail.
                            </p>
                            <footer >
                                <h3><strong>Ligne de commande</strong></h3>
                                <CodeBlockWrapper
                                    data={installCommands}
                                    showLineNumbers={false} />
                            </footer>
                            <footer >
                                <h3 style={{ display: 'flex' }}><strong>Sources : </strong>
                                    <a href="https://github.com/dips400">
                                        <img src="/assets/github.svg" className="icon" style={{ width: '35px', height: '35px' }} />
                                        Github
                                    </a>
                                </h3>
                                <p>Visitez notre page github pour suivre les différents tutoriels d'installation de la librairie côté client.</p>
                                <div style={{ display: "flex", height: 'min(40px,10vw)' }}>
                                    <a href="https://github.com/dips400/peasys-csharp"><img src="/assets/csharp.svg" alt="csharp" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-python"><img src="/assets/python_black.svg" alt="python" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-php"><img src="/assets/php-logo.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-java"><img src="/assets/java_black.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                </div>

                            </footer>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}