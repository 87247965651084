import updateHead from "../../../utils/helper/updatePageHead";
import CodeBlockWrapper from "../../Utils/CodeBlockWrapper";
import { connexionCode } from "../../../data/Documentation/ConnexionData";

export default function DocConnexion() {
    const title = "DIPS | Documentation Peasys";
    const cssPath = "/style/documentation.css";

    return (
        <>
            {updateHead({ title, cssPath })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Processus de connexion avec le Service Peasys</h1>
                                <h2 className="section-content-subtitle">Contrôles et sécurité</h2>
                            </header>
                            <p>
                                La <strong>connexion</strong> à votre IBMi se fait lors de la création d'une instance de PeaClient. Il est donc nécéssaire de lui passer plusieurs
                                paramètres : le nom de la partition, le port de connexion, le nom d'utilisateur du profil de connexion et son mot de passe ainsi que
                                la clé de license de votre abonnement au service Peasys.
                            </p>
                            <p>
                                La <strong>déconnexion</strong> se fait à l'aide d'une méthode et ferme le pont TCP entre le client et le serveur. Toutes les ressources nécéssaires
                                à l'utilisation du service sont libérées aux deux extrémités de l'API.
                            </p>
                            <p>
                                La connexion étant le point d'entrée de vos futurs logiciels sur l'IBMi, DIPS met à disposition différents processus de sécurité
                                afin d'assurer une protection sur-mesure. Dans tout les cas, la connexion requiert les identifiants d'un profil de la machine et
                                utilise les droits relatifs au profil. Les différences principales sont détaillées ci-dessous.
                            </p>
                        </section>

                        <section className="section-content-container">
                            <header>
                                <h1 className="section-subtitle-special">Processus de connexion ONLINE</h1>
                                <p>
                                    Dans ce processus, la connexion est pré-sécurisé par la récupération d'un token d'authentification sur nos serveurs.
                                </p>
                            </header>
                            <div className="section-columns-layout section-content-description pro-cons-container" grid-template="1,1">
                                <div>
                                    <h3>Avantages</h3>
                                    <span>
                                        <img src="/assets/check_green.png" style={{ height: '15px' }} /> Récupération optionnelle de logs de connexions
                                        et statistiques d'utilisation.
                                    </span>
                                    <span>
                                        <img src="/assets/check_green.png" style={{ height: '15px' }} /> Vérification dynamique de la clé de licence.
                                    </span>
                                    <span>
                                        <img src="/assets/check_green.png" style={{ height: '15px' }} /> Outre l'installation de la partie serveur, aucune
                                        autre action n'est requise lors de l'utilisation du service.
                                    </span>
                                </div>
                                <div>
                                    <h3>Inconvénients</h3>
                                    <span>
                                        <img src="/assets/cross-mark_red.png" style={{ height: '15px' }} /> Nécessite une connexion à internet pour accéder aux
                                        serveurs DIPS.
                                    </span>
                                </div>
                            </div>
                            <CodeBlockWrapper
                                data={connexionCode}
                                showLineNumbers={true} />
                        </section>

                        <section className="section-content-container">
                            <header>
                                <h1 className="section-subtitle-special">Processus de connexion OFFLINE</h1>
                                <p>
                                    Dans ce processus, la connexion est authentifié en interne sur votre serveur IBMi grâce au renseignement de la clé de 
                                    licence depuis Peasys Administrator. 
                                </p>
                            </header>
                            <div className="section-columns-layout section-content-description pro-cons-container" grid-template="1,1">
                                <div>
                                    <h3>Avantages</h3>
                                    <span>
                                        <img src="/assets/check_green.png" style={{ height: '15px' }} /> Ne nécessite pas de connexion à internet.
                                    </span>
                                </div>
                                <div>
                                    <h3>Inconvénients</h3>
                                    <span>
                                        <img src="/assets/cross-mark_red.png" style={{ height: '15px' }} /> Renseignement et mises à jour manuelles de la clé sur l'IBMi.
                                    </span>
                                    <span>
                                        <img src="/assets/cross-mark_red.png" style={{ height: '15px' }} /> Pas de collecte de logs et autres statistiques possibles.
                                    </span>
                                </div>
                            </div>
                            <CodeBlockWrapper
                                data={connexionCode}
                                showLineNumbers={true} />
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}