export const AnalysisServices = [
    {
        iconPath: '/assets/notes.png',
        title: 'Expressions des besoins',
        description: 'Souvent difficiles à les distinguer et à les exprimer. Elles sont le fondement du projet.'
    },
    {
        iconPath: '/assets/efficiency.png',
        title: 'Analyse fonctionnelle',
        description: 'Transformer les besoins en fonctionnalités et définir les outils pour les utiliser.'
    },
    {
        iconPath: '/assets/thinking.png',
        title: 'Rédaction de cahier des charges',
        description: 'Essentielle pour verbaliser et contractualiser nos relations.'
    }
]

export const DevServices = [
    {
        iconPath: '/assets/dev.png',
        title: 'Développement de logiciels métiers',
        description: 'La combinaison gagnante entre notre maîtrise des systèmes informatiques et nos expériences.'
    },
    {
        iconPath: '/assets/formation.png',
        title: 'Formation AS/400',
        description: 'Donner et partager nos connaissances, c’est pérenniser vos outils.'
    },
    {
        iconPath: '/assets/technology.png',
        title: 'Veille technologique',
        description: 'Nous les veillons autant que nous les inventons.'
    }
]

export const SupportServices = [
    {
        iconPath: '/assets/customer-care.png',
        title: 'Support & Assistance',
        description: 'L’assurance d’une continuité de vos logiciels à travers une tierce maintenance applicative.'
    },
    {
        iconPath: '/assets/planning.png',
        title: 'Suivi de projets ',
        description: 'un accompagnement complet de la genèse au résultat.'
    },
    {
        iconPath: '/assets/customer-support.png',
        title: 'Maintenance',
        description: 'Identification, analyse et résolution des incidents quotidiens.'
    }
]