import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { createLicenseKey, updateLicenseKey } from "../../../utils/api/licensekeyFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { sha256 } from "js-sha256";

export default function CheckoutForm({ infos }) {
    const stripe = useStripe();
    const elements = useElements();
    const {user } = useUser();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        var savedLicenseKey = null
        if (infos._id) {
            infos.is_available = false;
            savedLicenseKey = await updateLicenseKey(user, infos)
        } else {
            //infos.key = sha256(infos.partition_name + infos.model_number + infos.serie_number + infos.os_version)
            savedLicenseKey = await createLicenseKey(infos)
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `http://localhost:3000/payement/${savedLicenseKey._id}`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="section-payement-validation-button dark-bg">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"></div> : "Payer"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}