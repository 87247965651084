import { useState, useEffect } from "react";
import { checkEmptyInput } from "../../../utils/helper/inputHelper";
import { createCustomer, createSubscription } from "../../../utils/api/payementFetchs";
import { createLicenseKey, getLicenseKey } from "../../../utils/api/licensekeyFetchs";
import { updateUser } from "../../../utils/api/userFetchs";
import { sha256 } from 'js-sha256';
import { useUser } from "../../../utils/contexts/userContext";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import PackCard from "../../Pricing/tmpl/PackCard";
import { Install, Support_1, Support_2, Support_3 } from "../../../data/Pricing Plan/PackCardInfos";


export default function ConfigurationForm({ pricingPlan, infos, handleInfosChange, handleClientSecretChange }) {

    const { user, updateLocalUser } = useUser();
    const navigate = useNavigate();

    const [errorMessageConfiguration, setErrorMessageConfiguration] = useState("");
    const [errorMessageShipping, setErrorMessageShipping] = useState("");
    const [shippingData, setShippingData] = useState({
        name: user.lastname + ' ' + user.firstname,
        country: '',
        city: '',
        line1: '',
        postal_code: ''
    })

    const search = useLocation().search;
    const licenseKeyId = new URLSearchParams(search).get('keyId');

    const [nbrConnexions, setNbrConnexions] = useState([...Array(10).keys()])
    const [nbrMonths, setNbrMonths] = useState([...Array(37).keys()])
    const [packsSelected, setPacksSelected] = useState([])

    useEffect(() => {
        if (user._id && licenseKeyId) {
            getLicenseKey(licenseKeyId).then(key => handleInfosChange(key))
        }
    }, [user])

    async function initiatePayement(e) {
        e.preventDefault()

        if (infos.max_user_count === 0) {
            setErrorMessageConfiguration("Attention, vous avez selectionné 0 connexions pour cette clé de licence.")
            return;
        }

        if (!infos.partition_name || !infos.model_number || !infos.serie_number || !infos.os_version) {
            setErrorMessageConfiguration("Les champs * doivent être renseignés")
            return;
        }

        if (pricingPlan.pricing_plan === '2') {
            infos.max_user_count = pricingPlan.max_user_count;
            if (user.has_used_free_plan) {
                setErrorMessageConfiguration("Vous avez déjà bénéficié de l'abonnement découverte. Pour continuer d'utiliser Peasys, veuillez modifier votre abonnement depuis votre compte.")
                return;
            }
            
            //infos.key = sha256(infos.partition_name + infos.model_number + infos.serie_number + infos.os_version)
            const key = await createLicenseKey(infos)
            if (key) {
                user.has_used_free_plan = true
                updateUser(user);
                updateLocalUser(user)
                navigate('/account/subscriptions')
            }

        } else {
            infos.end_date = null

            if (user.customer_id) {
                // create subscritpion & retreive clientSecret
                createSubscription(user, pricingPlan.priceId, user.customer_id, infos.max_user_count).then(data => {
                    handleClientSecretChange(data.clientSecret)
                    // save subscriptionId
                    handleInfosChange((previous) => ({ ...previous, "subscription": { id: data.subscriptionId, item_id: data.subscriptionItemId } }))
                })
            } else {
                if (!shippingData.country || !shippingData.city || !shippingData.line1) {
                    setErrorMessageShipping("Les champs * doivent être renseignés")
                    return;
                }

                // create customer object stripe
                createCustomer(user, shippingData).then(customer => {
                    // save customerId
                    user.customer_id = customer.id
                    updateUser(user);
                    updateLocalUser(user)

                    // create subscritpion & retreive clientSecret
                    createSubscription(user, pricingPlan.priceId, customer.id, pricingPlan.max_user_count, infos.max_user_count).then(data => {
                        handleClientSecretChange(data.clientSecret)
                        // save subscriptionId
                        handleInfosChange((previous) => ({ ...previous, "subscription": { id: data.subscriptionId, item_id: data.subscriptionItemId } }))
                    })
                })
            }
        }
    }
    function handleEndDateChange(months) {
        if(isNaN(parseInt(months))) {
            setErrorMessageConfiguration("Le nombre de mois doit être un nombre.")
            return
        }
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        
        // Calculate the new month and year
        const newMonth = currentMonth + parseInt(months);
        const newYear = currentYear + Math.floor(newMonth / 12);
        
        // The month in Date constructor is 0-based, so we use modulo to get correct month
        const finalMonth = newMonth % 12;
        
        // Create the new date
        const newDate = new Date(newYear, finalMonth, currentDay);
        
        // Adjust if the day does not exist in the new month
        if (newDate.getMonth() !== finalMonth) {
            newDate.setDate(0);
        }
        
        handleInfosChange((previous) => ({ ...previous, "end_date": newDate }))
    }

    return (
        <>
            <form className="section-content-container" onSubmit={initiatePayement}>
                <h2 className="section-subtitle-special">Configuration</h2>

                {!licenseKeyId &&
                    <>
                        <div className="section-columns-layout" grid-template="1,1,1">
                            <div>
                                <label>Nom de la partition*</label>
                                <input type="text"
                                    onChange={(e) => handleInfosChange((previous) => ({ ...previous, "partition_name": e.target.value }))}
                                    onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                <label>Version de l'OS*</label>
                                <input type="text"
                                    onChange={(e) => handleInfosChange((previous) => ({ ...previous, "os_version": e.target.value }))}
                                    onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                            </div>

                            <div>
                                <label>Numéro de modèle*</label>
                                <input
                                    type="text"
                                    onChange={(e) => handleInfosChange((previous) => ({ ...previous, "model_number": e.target.value }))}
                                    onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                <label>Numéro de série*</label>
                                <input
                                    type="text"
                                    onChange={(e) => handleInfosChange((previous) => ({ ...previous, "serie_number": e.target.value }))}
                                    onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                            </div>

                            {pricingPlan.pricing_plan === '3' &&
                                <div>
                                    <label>Nombre de connexion(s)</label>
                                    <select
                                        options={nbrConnexions}
                                        onChange={(e) => handleInfosChange((previous) => ({ ...previous, "max_user_count": parseInt(e.target.value) }))}
                                        onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))}>
                                        {nbrConnexions.map((e, key) => {
                                            return <option key={key} value={e}>{e}</option>;
                                        })}
                                    </select>
                                    <label>Engagement (mois)</label>
                                    <select
                                        options={nbrMonths}
                                        onChange={(e) => handleEndDateChange(e.target.value)}
                                        onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))}>
                                        {nbrMonths.map((e, key) => {
                                            return <option key={key} value={e}>{e}</option>;
                                        })}
                                    </select>
                                </div>
                            }

                        </div>
                        <span className="error-text">{errorMessageConfiguration}</span>
                    </>
                }

                {pricingPlan.pricing_plan === '3' && !user.customer_id &&
                    <div>
                        <h2 className="section-subtitle-special">Détails de facturation</h2>
                        <div className='section-columns-layout' grid-template="1,1,1">
                            <div>
                                <label>Nom de l'entreprise</label>
                                <input type="text"
                                    onChange={(e) => setShippingData((previous) => ({ ...previous, "name": e.target.value ? e.target.value : user.lastname + ' ' + user.firstname }))} />
                                <label>Adresse*</label>
                                <input type="text"
                                    onBlur={(e) => setErrorMessageShipping(checkEmptyInput(e.target.value))}
                                    onChange={(e) => setShippingData((previous) => ({ ...previous, "line1": e.target.value }))} />
                            </div>
                            <div>
                                <label>Ville*</label>
                                <input
                                    type="text"
                                    onChange={(e) => setShippingData((previous) => ({ ...previous, "city": e.target.value }))}
                                    onBlur={(e) => setErrorMessageShipping(checkEmptyInput(e.target.value))} />
                                <label>Pays*</label>
                                <CountrySelect handleBillingInfoChange={setShippingData} />
                            </div>
                            <div>
                                <label>Code Postal</label>
                                <input
                                    type="text"
                                    onChange={(e) => setShippingData((previous) => ({ ...previous, "postal_code": e.target.value }))}
                                    onBlur={(e) => setErrorMessageShipping(checkEmptyInput(e.target.value))} />
                            </div>
                        </div>
                        <span className="error-text">{errorMessageShipping}</span>
                    </div>
                }

                <div>
                    <h2 className="section-subtitle-special">Pack supplémentaire</h2>
                    <div className="section-columns-layout" grid-template="1,1">
                        <div>
                            <PackCard data={Install} />
                        </div>
                        <div className="section-row-layout" style={{ gap: '.5rem' }}>
                            <PackCard data={Support_1} />
                            <PackCard data={Support_2} />
                            <PackCard data={Support_3} />
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className="section-subtitle-special">Termes et Conditions</h2>
                    <div>
                        <input type="checkbox" />
                        <a href="/assets/FI14853208536.pdf" without rel="noopener noreferrer" target="_blank" className="inline-link-file-download">
                            Contrat de licence Peasys
                        </a>
                    </div>
                    <div>
                        <input type="checkbox" />
                        <a href="/assets/FI14853208536.pdf" without rel="noopener noreferrer" target="_blank" className="inline-link-file-download">
                            Conditions Générales de ventes (CGV)
                        </a>
                    </div>
                </div>

                <button className="dark-bg">Valider</button>
            </form >
        </>
    )
}

const CountrySelect = ({ handleBillingInfoChange }) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    useEffect(() => {
        fetch("https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code")
            .then((response) => response.json())
            .then((data) => setCountries(data.countries));
    }, []);

    return (
        <select
            options={countries}
            value={selectedCountry}
            onChange={(selectedOption) => {
                setSelectedCountry(selectedOption.target.value)
                handleBillingInfoChange((previous) => ({ ...previous, "country": selectedOption.target.value }))
            }}>
            {countries.map((e, key) => {
                return <option key={key} value={e.value}>{e.label}</option>;
            })}
        </select>
    );
};