import updateHead from "../../../utils/helper/updatePageHead";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";

export default function AccountDeconnexion() {
    const title = "DIPS | Déconnexion";

    const { logoutLocalUser } = useUser();
    const navigate = useNavigate();

    function logoutUser() {
        logoutLocalUser()
        navigate('/')
    }

    return (
        <>
            {updateHead({ title })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <header>
                            <h1 className="section-content-title">Vous partez déjà ?..</h1>
                            <h2 className="section-content-subtitle">N'hésitez pas à nous retrouver sur vos réseaux préférés</h2>
                        </header>
                        <div className="section-content-description" style={{display:'flex', margin: '50px' }}>
                            <a style={{ display: "flex", height: '40px'}} href="https://www.linkedin.com/company/dips400">
                                <img src="/assets/linkedin.svg" alt="cshap" className="icon" />
                            </a>
                            <a style={{ display: "flex", height: '40px'}} href="https://github.com/dips400">
                                <img src="/assets/github.svg" className="icon" />
                            </a>
                        </div>
                        <button className="dark-bg" onClick={logoutUser}>Se déconnecter</button>
                    </div>
                </div>
            </section>
        </>
    )
}