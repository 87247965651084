export const installCommands = {
    csharp : {
        data: '$ dotnet add package Peasys'
    },
    python: {
        data: '$ pip install Peasys'
    },
    php: {
        data: 'composer require dips/peasys-php'
    },
    java: {
        data: 'TODO'
    },
    swift: {
        data: ``},
    ruby: {
        data: 'gem install peasys'}
}