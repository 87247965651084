import { useState } from 'react'
import { checkEmptyInput } from "../../../utils/helper/inputHelper";
import { sendOtp } from '../../../utils/api/userFetchs';

export default function AskForRecovery({ changeContent, email, changeEmail }) {
    const [errorMessage, setErrorMessage] = useState('')

    function sendRecoveryEmail(e) {
        e.preventDefault()

        if (!email) {
            setErrorMessage("L'e-mail n'est pas valide.")
            return
        }

        sendOtp(email).then(({ code, message }) => {
            code === 200 ? changeContent('TokenInput') : setErrorMessage(message)
        }).catch(error => console.log(error))
    }

    return (
        <div className="section-info-container centered-info-container">
            <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full"/>
            <p>Renseignez votre email afin de recevoir un code de récupération.</p>
            <div className="section-centered-layout">
                <form className="section-row-layout" onSubmit={sendRecoveryEmail}>
                    <div>
                        <label>E-mail*</label>
                        <input
                            type="text"
                            name="mail"
                            onChange={(e) => changeEmail(e.target.value)}
                            onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                    </div>
                    <div>
                        <p className='error-text'>{errorMessage}</p>
                        <button className="dark-bg">Envoyer un code de récupération</button>
                    </div>
                </form>
            </div>
        </div>
    )
}