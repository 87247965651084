import { Link } from 'react-router-dom'

export default function DocOverview() {
    return (
        <>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Introduction au Service Peasys</h1>
                                <h2 className="section-content-subtitle">Découvrez la mise en place et l'utilsation</h2>
                            </header>
                            <p>
                                La technologie Peasys a pour but de lier le monde de l’IBMi (AS/400) avec les technologies issues des environnements Windows,
                                macOS, Linux, voire mobiles tels que Android de Google ou iOS d'Apple. Peasys est donc une API permettant une communication
                                bidirectionnelle entre la base de données du serveur IBMi (DB2) et le code exécutable d’un environnement externe à l’IBMi.
                            </p>
                            <img src='/assets/Shema_DIPS.svg' className='icon' style={{margin: 'auto'}}/>
                        </section>
                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Côté Serveur</h1>
                            <div className="section-columns-layout" grid-template="1,1">
                                <p>
                                    La partie serveur de la solution Peasys est définie dans un sous-système dédié sur l’IBMi. Ce sous-système gère la connexion
                                    par utilisateur, l’exécution des requêtes du client et le renvoi des données.
                                </p>
                                <p>
                                    En termes de sécurité, Peasys utilise les droits définis à l’utilisateur en appliquant les allocations de ressources 
                                    au sous-système Peasys. <a href='/docs/install#peasys-administrator-install'>Installer Peasys Administrator</a>
                                </p>
                            </div>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Côté Client</h1>
                            <p>
                                Le client est défini au travers de la librairie (disponible dans différents langages de programmation) contenant l'ensemble des
                                fonctions nécéssaire à la communication avec le serveur. Cette librairie gère la connexion à l’IBMi, l'envoi des requêtes sur la
                                base de données de l’IBMi (DB2) ainsi que la mise en forme des données de retour.
                            </p>
                            <footer className="section-content-links-container">
                            <div style={{ display: "flex", height: 'min(40px,10vw)' }}>
                                    <a href="https://github.com/dips400/peasys-csharp"><img src="/assets/csharp.svg" alt="csharp" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-python"><img src="/assets/python_black.svg" alt="python" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-php"><img src="/assets/php-logo.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-java"><img src="/assets/java_black.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                </div>
                            </footer>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Trouver le bon abonnement</h1>
                            <div className="section-columns-layout" grid-template="1,1">
                                <p>
                                    Le service Peasys est une véritable technologie vous permettant de faciliter l'interaction avec l'IBMi tout en gardant la
                                    sécurité et les performances natives de celui-ci.
                                </p>
                                <p>
                                    Nous vous proposons un abonnement par connexion ainsi que différents packs d'aides. Réservez un rendez-vous pour découvrir 
                                    la solution et mettons en place une solution sur-mesure correspondant à vos besoins. 
                                </p>
                            </div>
                            <footer className="section-content-links-container">
                                <Link to='/pricing'>
                                    <button className="dark-bg">Essayer gratuitement</button>
                                </Link>
                                <Link to='/pricing'>
                                    <button className="dark-bg">Voir les tarifs</button>
                                </Link>
                            </footer>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}