import updateHead from "../../utils/helper/updatePageHead";
import Footer from "../Footer/Footer";

export default function Products() {
    const title = "DIPS | Nos produits";
    const cssPath = "/style/products.css";

    return(
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Profitez de notre <span className="highlight-title">bibliothèque logiciels</span> développés avec Peasys</h1>
                                <h2 className="section-content-subtitle">Des logiciels basés sur notre expérience métier</h2>
                            </header>
                            
                            <h1>à venir...</h1>
                            <button className="dark-bg">Contactez-nous</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}