
import React, { useState, useEffect } from "react";
import updateHead from "../../utils/helper/updatePageHead";
import AccountDeconnexion from "./SubPages/AccountDeconnexion";
import AccountDashboard from "./SubPages/AccountDashboard";
import AccountModification from "./SubPages/AccountModification";
import AccountStatistics from "./SubPages/AccountStatistics";
import AccountSubscriptions from "./SubPages/AccountSubscriptions";
import { Link } from "react-router-dom";
import AccountSubscription from "./SubPages/AccountSubscription";
import { useUser } from "../../utils/contexts/userContext";
import AccountAdminPanel from "./SubPages/AccountAdminPanel";

export default function Account({ page }) {
    const title = "Compte";
    const cssPathPage = "/style/account.css";
    const cssPathComponent = "/style/sidenavbar.css";

    const { user } = useUser()
    const [openSideNav, setOpenSideNav] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => { setWindowWidth(window.innerWidth) }

    useEffect(() => {
        const handleClickSideNav = (e) => {
            if (e.target.alt !== "sidenavmenu") {
                setOpenSideNav(false)
            }
        }
        document.body.addEventListener('click', handleClickSideNav)

        return () => document.body.removeEventListener('click', handleClickSideNav)
    }, [])

    useEffect(() => {
        const elems = document.querySelectorAll(".section-sidenav-navbar-list-item")

        elems.forEach(function (elem) {
            elem.addEventListener("click", function () {
                document.querySelector(".selected-sidenav-item")?.classList.remove('selected-sidenav-item')
                elem.classList.add("selected-sidenav-item")
            });
        });
    }, [])

    if (!user._id) {
        window.history.back()
    } else {

        return (
            <>
                {updateHead({ title, cssPaths: [cssPathPage, cssPathComponent] })}
                <section className="section-sidenav-layout">
                    {windowWidth < 1200 &&
                        (<div className="section-sidenav-menu-icon-container">
                            <img
                                src="/assets/menu.png"
                                className="icon section-sidenav-menu-icon" alt="sidenavmenu"
                                onClick={() => setOpenSideNav(!openSideNav)} />
                        </div>
                        )}
                    <div className={"section-sidenav-container " + (windowWidth < 1200 ? "collapsed " : "") + (openSideNav ? "open" : "")}>
                        <ul className="section-sidenav-navbar-itemslist">

                            <Link to='/account'>
                                <li key='acc1' className="section-sidenav-navbar-list-item selected-sidenav-item" tabIndex='10'>
                                    Tableau de bord
                                </li>
                            </Link>
                            <Link to='/account/modification'>
                                <li key='acc2' className="section-sidenav-navbar-list-item" tabIndex='11'>
                                    Modifier votre compte
                                </li>
                            </Link>
                            <Link to='/account/subscriptions'>
                                <li key='acc3' className="section-sidenav-navbar-list-item" tabIndex='12'>
                                    Gérer vos abonnements
                                </li>
                            </Link>
                            <Link to='/account/statistics'>
                                <li key='acc4' className="section-sidenav-navbar-list-item" tabIndex='13'>
                                    Vos statistiques
                                </li>
                            </Link>

                            {user.is_admin &&
                                <Link to='/account/admin-panel'>
                                    <li key='acc5' className="section-sidenav-navbar-list-item" tabIndex='14'>
                                        Panneau d'administration
                                    </li>
                                </Link>
                            }

                            <Link to='/contact'>
                                <li key='acc6' className="section-sidenav-navbar-list-item" tabIndex='15'>
                                    Contactez-nous
                                </li>
                            </Link>
                            <Link to='/account/deconnexion'>
                                <li key='acc7' className="section-sidenav-navbar-list-item" tabIndex='16'>
                                    Déconnexion
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="section-rightcolumn-content">

                        {(() => {
                            switch (page) {
                                case "dashboard":
                                    return <AccountDashboard />
                                case "modification":
                                    return <AccountModification />
                                case "subscriptions":
                                    return <AccountSubscriptions />
                                case "subscriptions/subscription":
                                    return <AccountSubscription />
                                case "statistics":
                                    return <AccountStatistics />
                                case "admin-panel":
                                    return <AccountAdminPanel />
                                case "deconnexion":
                                    return <AccountDeconnexion />
                                default:
                                    return <AccountDashboard />
                            }
                        })()}

                    </div>
                </section>
            </>
        )
    }
}