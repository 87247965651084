import Loader from "react-js-loader";
import { Link, useParams } from "react-router-dom"
import { useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect, useCallback } from 'react'
import { useUser } from "../../utils/contexts/userContext";
import { getLicenseKey, updateLicenseKey } from "../../utils/api/licensekeyFetchs";
import { updateUser } from "../../utils/api/userFetchs";

export default function PayementStatus() {

    const { user, updateLocalUser } = useUser()
    const { licenseKeyId } = useParams()
    const stripe = useStripe();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    const registerKey = useCallback(async () => {
        var key = await getLicenseKey(licenseKeyId)
        key.is_available = true
        const updatedLicenseKey = await updateLicenseKey(user, key)
        await updateUser(user)
        updateLocalUser(user)
        setIsLoading(false)
    }, [user, licenseKeyId, updateLocalUser])

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    registerKey()
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });

    }, [stripe, registerKey]);

    if (isLoading) {
        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <div className="section-vertical-centered-layout">
                                <Loader type="bubble-ping" color="#071F5A" bgColor="#071F5A" size={200} />
                                <h2>Processing...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout">
                    <div className="section-content-container">
                        <div className="section-vertical-centered-layout">
                            <h2>{message}</h2>
                            <Link to="/account/subscriptions">
                                <button className="dark-bg">Voir mes clés</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}