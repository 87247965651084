const api_url = process.env.NODE_ENV === "production" ? "https://dips400.com" : "http://localhost:8080"

export async function createLicenseKey(key, userId) {
    try {
        const response = await fetch(`${api_url}/api/license-key/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({key : key, userId: userId})
            })

        switch (response.status) {
            case 201:
                return await response.json();
            case 404:
                return null;
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function updateLicenseKey(user, key) {
    try {
        const response = await fetch(`${api_url}/api/license-key/update`,
            {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify(key)
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function getLicenseKey(licenseKeyId) {
    try {
        const response = await fetch(`${api_url}/api/license-key/${licenseKeyId}`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                }
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function deleteLicenseKey(licensekeyID) {
    try {
        const response = await fetch(`${api_url}/api/license-key/delete/${licensekeyID}`,
            {
                method: 'DELETE'
            })

    } catch (error) {
        console.error(error.message)
    }
}