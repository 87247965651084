import updateHead from "../../utils/helper/updatePageHead";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

export default function Library() {
    const title = "DIPS | Découvrez la libraire Peasys";
    const cssPath = "/style/library.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            {/*<section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <div className="section-library-iframe-background" />
                            <h2 className="section-content-title">Découvrez <span className="highlight-title">le service Peasys</span> en 3 minutes</h2>
                            <iframe
                                className="section-library-iframe"
                                src="https://www.youtube.com/embed/J9w-cir5a6U?si=xmJwfjjH-sYMsZYq"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen frameBorder={0} />
                        </div>
                    </div>
                </div>
    </section>*/}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <div className="section-columns-layout" grid-template="1,1">
                                <header>
                                    <h1 className="section-content-title">
                                        <span className="highlight-title">Peasys</span> réconcilie le meilleur des 2 mondes au sein d'un seul outil
                                        <span className="highlight-title"> puissant</span> et <span className="highlight-title">sécurisé</span>
                                    </h1>
                                    <h2 className="section-content-subtitle">L'accès à votre IBMi de partout</h2>
                                </header>
                            </div>
                            <div className="section-content-description">
                                <div className="section-columns-layout" grid-template="1,1">
                                    <p>
                                        Le <a href="https://github.com/dips400">service Peasys</a> est né du constat simple qu’aucune solution sur le marché actuel de la modernisation 
                                        de l’IBMi ne regroupe les quatre aspects suivants : <strong>performance native</strong>, <strong>sécurité native</strong>,
                                        <strong> modernité du langage de développement</strong> et <strong>facilité de prise en main de la solution</strong>.
                                    </p>
                                    <p>
                                        Ce service est une <strong>API</strong> qui s’adresse aux développeurs souhaitant créer des applications
                                        faisant intervenir <strong>la manipulation de la base de données</strong> d’un serveur AS/400. Peasys définit donc un
                                        ensemble de règles et d’outils pour assurer une <strong>communication performante</strong> et <strong>sécurisée </strong> 
                                        de bout en bout.
                                    </p>
                                </div>
                            </div>
                            <footer className="section-content-links-container">
                                <Link to="/docs">
                                    <button className="dark-bg">En savoir plus</button>
                                </Link>
                                <Link to="/appointement-schedule">
                                    Besoin d'un développement ?
                                </Link>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1">
                            <div className="section-row-layout">
                                <div className="section-info-container section-library-keypoints left">
                                    <h3>Performance native</h3>
                                    <p>La technologie Peasys permet un transitoire très élevé grâce à un lien direct en full TCP sans couche intermédiaire.</p>
                                </div>
                                <div className="section-info-container section-library-keypoints right">
                                    <h3>Sécurité native</h3>
                                    <p>Peasys respecte les règles de sécurité du l’IBMi car les droits d’accès et listes de restrictions sont gérées coté AS/400.</p>
                                </div>
                            </div>
                            <div className="section-row-layout">
                                <div className="section-info-container section-library-keypoints left">
                                    <h3>Modernité de développement</h3>
                                    <p>Le choix grandissant de langage de programmation offre des multiples outils de développement pour vos applicatifs AS/400.</p>
                                </div>
                                <div className="section-info-container section-library-keypoints right">
                                    <h3>Facilité de prise en main</h3>
                                    <p>Peasys met en avant l'ergonomie de ses outils pour faciliter l'interaction avec l'IBMi.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}