import updateHead from "../../../utils/helper/updatePageHead";
import { useUser } from "../../../utils/contexts/userContext";
import PhoneInput from "react-phone-input-2";
import { checkEmptyInput, disableButton } from "../../../utils/helper/inputHelper";
import { useState } from "react";
import { updateUser } from "../../../utils/api/userFetchs";
import bcrypt from "bcryptjs-react"

export default function AccountModification() {
    const title = "DIPS | Modifier votre compte";

    const { user, updateLocalUser } = useUser();

    const [updatedUser, setUpdatedUser] = useState(user)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function handleUpdate(e) {
        e.preventDefault();

        setIsLoading(true)
        
        const salt = await bcrypt.genSalt(updatedUser.password.size);
        updatedUser.password = await bcrypt.hash(updatedUser.password, salt)

        const user = await updateUser(updatedUser);
        updateLocalUser(user)
        setMessage("Les modifications ont bien été enregistrées :)")
        setIsLoading(false)
    }
    return (
        <>
            {updateHead({ title })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout">
                            <header>
                                <h1 className="section-content-title">Modifiez votre compte</h1>
                            </header>
                            <div className="section-info-container" style={{width:'unset'}}>
                                <h2>Vos informations</h2>
                                <form className="section-row-layout" onSubmit={handleUpdate}>
                                    <div className="section-columns-layout" grid-template="1,1">
                                        <div className="section-account-personal-infos-container">
                                            <span>
                                                <label>Nom</label>
                                                <input
                                                    type="text"
                                                    defaultValue={user.lastname}
                                                    onChange={(e) => setUpdatedUser((previous) => ({ ...previous, "lastname": e.target.value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                            <span>
                                                <label>Mail</label>
                                                <input
                                                    type="text"
                                                    defaultValue={user.mail}
                                                    onChange={(e) => setUpdatedUser((previous) => ({ ...previous, "mail": e.target.value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                            <span>
                                                <label>Entreprise</label>
                                                <input
                                                    type="text"
                                                    defaultValue={user.company}
                                                    onChange={(e) => setUpdatedUser((previous) => ({ ...previous, "company": e.target.value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                        </div>
                                        <div className="section-account-personal-infos-container">
                                            <span>
                                                <label>Prénom</label>
                                                <input
                                                    type="text"
                                                    defaultValue={user.firstname}
                                                    onChange={(e) => setUpdatedUser((previous) => ({ ...previous, "firstname": e.target.value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                            <span>
                                                <label>Mot de passe </label>
                                                <input
                                                    type="text"
                                                    defaultValue={""}
                                                    onChange={(e) => setUpdatedUser((previous) => ({ ...previous, "password": e.target.value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                            <span>
                                                <label>Téléphone</label>
                                                <PhoneInput
                                                    style={{ marginBottom: '5px', marginTop: '5px' }}
                                                    country={'fr'}
                                                    value={user.phone}
                                                    onChange={(value) => setUpdatedUser((previous) => ({ ...previous, "phone": value }))}
                                                    onBlur={(e) => checkEmptyInput(e.target.value)} />
                                            </span>
                                        </div>

                                    </div>
                                    {message ?
                                        <span>{message}</span> :
                                        <button
                                            disabled={disableButton(updatedUser) || isLoading}
                                            className={"dark-bg " + (isLoading ? "loading-button" : undefined)}>Valider</button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}