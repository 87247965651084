import { useState, useEffect } from "react";
import updateHead from "../../utils/helper/updatePageHead";
import DocInstallation from "./Subpages/DocInstallation";
import DocConnexion from "./Subpages/DocConnexion.js";
import DocUseCases from "./Subpages/DocUseCases.js";
import DocSqlQueries from "./Subpages/DocSqlQueries.js";
import DocIbmiQueries from "./Subpages/DocIbmiQueries.js";
import DocOverview from "./Subpages/DocOverview.js";
import { Link } from "react-router-dom";

export default function Documentation({ page }) {
    const title = "DIPS | Documentation Peasys";
    const cssPathPage = "/style/documentation.css";
    const cssPathComponent = "/style/sidenavbar.css";

    const [dropDownSqlQuery, setDropDownSqlQuery] = useState(false)
    const [dropDownIbmQuery, setDropDownIbmQuery] = useState(false)
    const [dropDownUseCases, setDropDownUseCases] = useState(false)

    const [openSideNav, setOpenSideNav] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => { setWindowWidth(window.innerWidth) }

    useEffect(() => {
        const handleClick = (e) => {
            if (e.target.alt !== "sidenavmenu") {
                setOpenSideNav(false)
            }
        }
        document.body.addEventListener('click', handleClick)

        return () => document.body.removeEventListener('click', handleClick)
    }, [])

    useEffect(() => {
        const elems = document.querySelectorAll(".section-sidenav-navbar-list-item")
        
        elems.forEach(function (elem) {
            elem.addEventListener("click", function () {
                document.querySelector(".selected-sidenav-item")?.classList.remove('selected-sidenav-item')
                elem.classList.add("selected-sidenav-item")
            });
        });
    }, [])

    return (
        <>
            {updateHead({ title, cssPaths: [cssPathPage, cssPathComponent] })}
            <section className="section-sidenav-layout">
                {windowWidth < 1200 &&
                    (
                        <div className="section-sidenav-menu-icon-container">
                            <img
                                src="/assets/menu.png"
                                className="icon section-sidenav-menu-icon" alt="sidenavmenu"
                                onClick={() => setOpenSideNav(!openSideNav)} />
                        </div>
                    )}
                <div className={"section-sidenav-container " + (windowWidth < 1200 ? "collapsed " : "") + (openSideNav ? "open" : "")}>
                    <ul className="section-sidenav-navbar-itemslist">
                        <Link to='/docs'>
                            <li key='doc1' className="section-sidenav-navbar-list-item selected-sidenav-item" tabIndex='1'>
                                Présentation
                            </li>
                        </Link>
                        <Link to='/docs/install'>
                            <li key='doc2' className="section-sidenav-navbar-list-item" tabIndex='2'>
                                Installation
                            </li>
                        </Link>
                        <Link to='/docs/connexion'>
                            <li key='doc3' className="section-sidenav-navbar-list-item" tabIndex='3'>
                                Connexion
                            </li>
                        </Link>
                        <Link to='/docs/sql-query'>
                            <li key='doc4' className="section-sidenav-navbar-list-item" onClick={() => setDropDownSqlQuery(!dropDownSqlQuery)} tabIndex='4'>
                                Requêtes SQL
                                <img key='doc5' className={dropDownSqlQuery ? "dropdowned" : undefined} src="/assets/angle-right-solid.svg" alt="toggle" style={{ width: '35px', height: '20px' }} />
                            </li>
                        </Link>
                        {dropDownSqlQuery &&
                            <ul className="section-documentation-navbar-dropdown-itemslist">
                                <a href="#section-sql-create" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_1'>CREATE</li>
                                </a>
                                <a href="#section-sql-delete" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_2'>DELETE</li>
                                </a>
                                <a href="#section-sql-insert" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_3'>INSERT</li>
                                </a>
                                <a href="#section-sql-select" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_8'>SELECT</li>
                                </a>
                                <a href="#section-sql-update" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_4'>UPDATE</li>
                                </a>
                                <a href="#section-sql-alter" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_5'>ALTER</li>
                                </a>
                                <a href="#section-sql-drop" className="section-documentation-navbar-dropdown-list-item">
                                    <li key='doc_2_6'>DROP</li>
                                </a>
                            </ul>
                        }
                        <Link to='/docs/ibmi-query' >
                            <li key='doc6' className="section-sidenav-navbar-list-item" onClick={() => setDropDownIbmQuery(!dropDownIbmQuery)} tabIndex='5'>
                                Commandes IBMi
                            </li>
                        </Link>
                        {dropDownIbmQuery && null
                            /*<ul className="section-documentation-navbar-dropdown-itemslist">
                            <img src="/assets/angle-right-solid.svg" className={dropDownIbmQuery ? "dropdowned" : undefined} alt="toggle" style={{ width: '35px', height: '20px' }} />
                                <li key='doc_2_8' className="section-documentation-navbar-dropdown-list-item">test</li>
                            </ul>*/
                        }
                        {dropDownUseCases && null
                            /*<Link to='/docs/use-cases' >
                                <li key='doc7' className="section-sidenav-navbar-list-item" onClick={() => setDropDownUseCases(!dropDownUseCases)} tabIndex='6'>
                                    Cas pratiques
                                    <img src="/assets/angle-right-solid.svg" className={dropDownUseCases ? "dropdowned" : undefined} alt="toggle" style={{ width: '35px', height: '20px' }} />
                                </li>
                            </Link>
                                <ul className="section-documentation-navbar-dropdown-itemslist">
                                    <li key='doc_3_8' className="section-documentation-navbar-dropdown-list-item">test</li>
                                </ul>*/
                        }
                    </ul>
                </div>
                <div className="section-rightcolumn-content">

                    {(() => {
                        switch (page) {
                            case "overview":
                                return <DocOverview />
                            case "install":
                                return <DocInstallation />
                            case "connexion":
                                return <DocConnexion />
                            case "sql-query":
                                return <DocSqlQueries />
                            case "ibmi-query":
                                return <DocIbmiQueries />
                            case "use-cases":
                                return <DocUseCases />
                            default:
                                return <DocOverview />
                        }
                    })()}

                </div>
            </section>
        </>
    )
}