export const connexionCode = {
    csharp: {
        data: `using Peasys;

namespace ConnexionPeasys
{
    class Main
        {
        static void Main(string[] args)
        {
            // connexion
            PeaClient conn = new PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion: true, retreiveStatistics: false);
            Console.WriteLine("Status de connexion : " + conn.ConnectionMessage);

            // execute queries

            // deconnexion
            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion=True, retreiveStatistics=False)
print("Status de connexion : " + conn.connexion_message)

// execute queries

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;

// execute queries

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
            
            // execute queries
        
            conn.disconnect();
        } catch (Exception e) {
            System.out.println(e.getMessage());
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

    let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
        retrieveStatitics: false, onlineVersion: true)
    conn.disconnect()
`   
    },
    ruby: {
        data: `require 'peasys_swift'

    conn = PeaClient.new("PARITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", false, false)
    conn.disconnect
`   
    }
}