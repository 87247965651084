import { useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';

export default function CodeBlockWrapper({ data, showLineNumbers }) {

    const [language, setLanguage] = useState('csharp');

    return (
        <div className='section-codeblock'>
            <footer>
                <div style={{ display: "flex", height: '30px', margin:'15px 0'}}>
                    <img src="/assets/csharp.svg" alt="cshap" className="icon" onClick={() => setLanguage('csharp')} />
                    <img src="/assets/python_black.svg" alt="python" className="icon" onClick={() => setLanguage('python')} />
                    <img src="/assets/php-logo.svg" alt="php" className="icon" onClick={() => setLanguage('php')} />
                    <img src="/assets/java_black.svg" alt="java" className="icon" onClick={() => setLanguage('java')} />
                </div>
            </footer>
            <CopyBlock
                text={data[language].data}
                language={showLineNumbers ? language : 'bash'}
                showLineNumbers={showLineNumbers}
                theme={dracula}
                customStyle={{ fontSize: '14px' }}/>
        </div>
    )
}